.form-demo .card {
  min-width: 450px;
}
.form-demo .card form {
  margin-top: 2rem;
}
.form-demo .card .field {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
  .form-demo .card {
    width: 80%;
  }
}
@media screen and (max-width: 640px) {
  .form-demo .card {
    width: 100%;
    min-width: 0;
  }
}
.MuiModal-root {
  z-index: 0 !important;
}
.MuiBackdrop-root css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  z-index: 0 !important;
}
